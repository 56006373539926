import React from 'react';
import styled from 'styled-components';

import * as NavUtil from '../../Utils/NavUtil';
import Horizontal from './horizontal';
import Vertical from './vertical';

export default function SiteFooter(props) {
  return (
    <Wrapper>
      <img src="/realiT-logo.png" style={{width: '80%', maxWidth: 250}} />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: 60}}>
        <div
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <img src="/mail.png" width="24" />
          <div
            style={{
              cursor: 'pointer',
              color: '#808080',
              fontSize: 18,
              marginLeft: 20,
            }}
            onClick={() => window.open('mailto:contact+realiT@revteltech.com')}>
            contact+realiT@revteltech.com
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <img src="/web.png" width="24" />
          <div
            style={{
              cursor: 'pointer',
              color: '#808080',
              fontSize: 18,
              marginLeft: 20,
            }}
            onClick={() => window.open('https://www.revtel.tech/')}>
            https://www.revtel.tech/
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <img src="/fb.png" width="24" />
          <div
            style={{
              cursor: 'pointer',
              color: '#808080',
              fontSize: 18,
              marginLeft: 20,
            }}
            onClick={() => window.open('https://www.facebook.com/RealiT.Tech')}>
            RealiT FB
          </div>
        </div>
      </div>
      <div style={{marginTop: 60, fontSize: 16}}>
        COPYRIGHT ©RevtelTech ALL RIGHT RESERVED
      </div>
      <a
        style={{marginTop: 10, fontSize: 14, color: '#61481C'}}
        href="/privacy">
        {props.lang === 'tw' ? '隱私權政策' : 'Privacy'}
      </a>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  padding-top: 120px;
  padding-bottom: 40px;

  background: linear-gradient(rgba(0, 255, 188, 0), #BEFFEE);

  width:100%;
  
}
`;
