import React from 'react';
import styled from 'styled-components';
import NavLink from '../NavLink';
import * as NavUtil from '../../Utils/NavUtil';

export default function SiteFooter(props) {
  const footerConfig = NavUtil.getFooterConfig(props);
  const navItems = NavUtil.getFooterNavItems(props);

  return (
    <Wrapper id="rev-SiteFooter">
      <div className="content">
        <div className="inner">
          <img
            className="site-logo"
            src="/images/site-logo.png"
            alt="site footer"
          />

          <div className="inner-right">
            <div className="nav-items">
              {navItems.map((navItem, idx) => {
                return (
                  <>
                    {idx !== 0 && (
                      <div className="bar" key={`${idx}_bar`}>
                        {'/'}
                      </div>
                    )}

                    <NavLink key={idx} to={navItem.path}>
                      {navItem.name}
                    </NavLink>
                  </>
                );
              })}
            </div>

            <div className="social-items">
              {footerConfig.social_fb && (
                <a
                  href={footerConfig.social_fb}
                  target="_blank"
                  rel="noreferrer">
                  <img src="/images/fb.png" alt="fb" />
                </a>
              )}

              {footerConfig.social_ig && (
                <a
                  href={footerConfig.social_ig}
                  target="_blank"
                  rel="noreferrer">
                  <img src="/images/ig.png" alt="ig" />
                </a>
              )}

              {footerConfig.social_line && (
                <a
                  href={footerConfig.social_line}
                  target="_blank"
                  rel="noreferrer">
                  <img src="/images/line.png" alt="fb" />
                </a>
              )}
            </div>

            <div className="contact-items">
              <div className="title">Contact Us</div>
              <div className="email">
                {footerConfig.contact_email || 'contact@hishop.com'}
              </div>
              {footerConfig.contact_addr && (
                <div className="addr">{footerConfig.contact_addr}</div>
              )}
            </div>

            <div className="contact-items">
              {footerConfig.contact_phone && (
                <div className="phone">{footerConfig.contact_phone}</div>
              )}
              {footerConfig.contact_time && (
                <div className="time">{footerConfig.contact_time}</div>
              )}
            </div>
          </div>
        </div>

        <div className="copyright">
          {footerConfig.copyright || '2021 HiShop Powered By Revteltech'}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  & > .content {
    max-width: 720px;
    margin: 0 auto;

    & > .inner {
      display: flex;
      align-items: center;

      & > img.site-logo {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      & > .inner-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > .nav-items {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-bottom: 10px;

          & > *:not(:last-child) {
            margin: 0 5px;
          }
        }

        & > .social-items {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-bottom: 10px;

          & > * {
            margin: 0 5px;

            & img {
              width: 32px;
              height: 32px;
              object-fit: contain;
              border-radius: 16px;
              overflow: hidden;
              background-color: #888;
            }
          }
        }

        & > .contact-items {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;

          & > * {
            margin: 3px 0;
            font-size: 0.9rem;
            text-align: right;
            color: #666;
            display: flex;
            align-items: center;

            &.title {
              font-weight: 500;
              color: black;
            }

            &:not(:last-child)::after {
              content: '|';
              margin: 0 6px;
              color: #888;
            }
          }

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            & > * {
              &:not(:last-child)::after {
                display: none;
              }
            }
          }
        }
      }
    }

    & > .copyright {
      width: 100%;
      color: #888;
      text-align: center;

      &::before {
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        margin: 15px 0;
        background-color: #ccc;
      }
    }
  }
`;
