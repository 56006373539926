import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import NavLink from '../NavLink';
import constants from '../../constants';
import * as AppActions from '../../AppActions';
import {ShoppingBag} from '@styled-icons/boxicons-regular/ShoppingBag';
import {PersonCircle} from '@styled-icons/ionicons-outline/PersonCircle';
import {Menu as MenuIcon} from '@styled-icons/boxicons-regular/Menu';
import MobileNavDrawer from './MobileNavDrawer';
import * as NavUtil from '../../Utils/NavUtil';

export default function SiteNavBar(props) {
  function scrollTo(sectionId, evt) {
    evt.preventDefault();
    const elem = document.getElementById(sectionId);
    if (elem) {
      const top = elem.offsetTop;
      window.scrollTo({top: top - 60, behavior: 'smooth'});
    }
  }

  return (
    <div
      style={{
        height: 64,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: 1200,
          width: '80%',
        }}>
        <img src="/realiT-logo.png" height="32" />
        <div
          style={{
            fontFamily: 'HelveticaNeue',
            fontSize: '1em',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <div
            style={{cursor: 'pointer'}}
            onClick={scrollTo.bind(null, 'intro')}>
            {props.lang === 'en' ? 'Features' : '功能介紹'}
          </div>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <div
            style={{cursor: 'pointer'}}
            onClick={scrollTo.bind(null, 'contact')}>
            {props.lang === 'en' ? 'Contact' : '聯絡我們'}
          </div>
        </div>
      </div>
    </div>
  );

  /*
  const {location} = props;
  const [user] = useOutlet('user');
  const [cart] = useOutlet('cart');
  const [dimension] = useOutlet('dimension');
  const showLoginModal = useOutletSetter('login-modal');
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const mobile = dimension.rwd !== 'desktop';
  const navItems = NavUtil.getNavItems(props);

  React.useEffect(() => {
    setMobileNavVisible(false);
  }, [location?.pathname]);

  const navItemContent = (
    <>
      <div className="nav-items">
        {navItems.map((navItem, idx) => {
          const selected = NavUtil.isNavLinkSelected(navItem);

          return (
            <NavLink
              {...NavUtil.getNavLinkExtraProps(navItem)}
              to={navItem.path}
              className="nav-link"
              key={idx}
              extraStyle={{
                color: '#000',
                fontWeight: '400',
                borderBottom: selected
                  ? `4px solid ${constants.THEME_COLOR}`
                  : '4px solid transparent',
                padding: '19px 10px',
                margin: '0px 20px',
              }}>
              {navItem.name}
            </NavLink>
          );
        })}
      </div>

      {user ? (
        <>
          <button
            className="nav-round-btn-box"
            onClick={async () => {
              await AppActions.navigate('/checkout', {loading: 500});
            }}>
            <ShoppingBag size={24} />
            <div>購物車</div>
            {cart?.items?.length > 0 && <div>({cart.items.length})</div>}
          </button>

          <button
            className="nav-round-btn-box"
            style={{
              backgroundColor: 'transparent',
            }}
            onClick={async () => {
              await AppActions.navigate('/profile', {loading: 500});
            }}>
            <PersonCircle size={26} color={constants.THEME_COLOR} />
            {mobile && (
              <div style={{color: constants.THEME_COLOR}}>個人檔案</div>
            )}
          </button>
        </>
      ) : (
        <button
          className="nav-round-btn-box"
          onClick={() => {
            showLoginModal(true);
          }}>
          <PersonCircle size={26} color={'white'} />
          <div>登入</div>
        </button>
      )}
    </>
  );

  return (
    <Wrapper id="rev-SiteNavBar">
      <div className="logo-box">
        <NavLink to="/" loading={800}>
          <img
            src="/images/site-logo.png"
            alt="Logo"
            style={{
              height: 40,
              objectFit: 'contain',
            }}
          />
        </NavLink>
      </div>

      {!mobile ? (
        navItemContent
      ) : (
        <>
          <div style={{flex: 1}} />
          <div
            className="menu-btn-box"
            onClick={() => setMobileNavVisible(true)}>
            <MenuIcon size={24} />
          </div>
        </>
      )}

      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
        {...props}>
        {navItemContent}
      </MobileNavDrawer>
    </Wrapper>
  );
  */
}
