import {css} from 'styled-components';
import constants from '../../constants';

export default css`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 840px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  --primaryColor: ${constants.THEME_COLOR};

  min-height: 100vh;

  #rev-ProductListPage {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding-bottom: 20px;

    & > .content > div > div > ul.ant-menu {
      position: sticky;
      top: calc(var(--topNavBarHeight) + 20px);
    }

    & .rev-mobile-menu-btn {
      right: -8px;
      top calc(var(--topNavBarHeight) + 20px);

      & > button {
        display: none;
      }

      &::before {
        display: block;
        content: '產品列表';
        padding: 8px 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: var(--primaryColor);
        color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
  }

  #rev-ArticleListPage {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding-bottom: 20px;

    & > .content > div > div > ul.ant-menu {
      position: sticky;
      top: calc(var(--topNavBarHeight) + 20px);
    }

    & .rev-mobile-menu-btn {
      right: -8px;
      top calc(var(--topNavBarHeight) + 20px);

      & > button {
        display: none;
      }

      &::before {
        display: block;
        content: '文章列表';
        padding: 8px 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: var(--primaryColor);
        color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
  }

  #rev-CartPage {
  }

  #rev-CheckoutInfoPage {
  }

  #rev-CheckoutReviewPage {
  }

  #rev-OrderPage {
  }

  #rev-ProductDetailPage {
    & > .content > .categories > ul.ant-menu {
      position: sticky;
      top: calc(var(--topNavBarHeight) + 20px);
    }
  }

  #rev-ArticleDetailPage {
    & > .content > .categories > ul.ant-menu {
      position: sticky;
      top: calc(var(--topNavBarHeight) + 20px);
    }
  }
`;
