import React from 'react';
import styled from 'styled-components';
import useDetectDimension from 'rev.sdk.js/Hooks/useDetectDimension';
import LoginModal from '../Components/LoginModal';
import ResetPasswordModal from '../Components/ResetPasswordModal';
import GlobalSpinner from '../Components/GlobalSpinner';
import * as CtxUtil from '../Utils/CtxUtil';
import styledCss from './styledCss';
import customStyledCss from './customStyledCss';

function Provider(props) {
  useDetectDimension();

  React.useEffect(() => {
    CtxUtil.onCtxRendered();
  }, []);

  return (
    <Wrapper id="rev-AppContext">
      {props.children}

      <LoginModal />
      <ResetPasswordModal />
      <GlobalSpinner />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${styledCss}
  ${customStyledCss}
`;

export {Provider};
