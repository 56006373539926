import {css} from 'styled-components';

export default css`
  /* #rev-ProductListPage {
    & .browser-header {}
  } */

  /* #rev-ArticleListPage {
  } */

  /* #rev-CartPage {
  } */

  /* #rev-CheckoutInfoPage {
  } */

  /* #rev-CheckoutReviewPage {
  } */

  /* #rev-OrderPage {
  } */
`;
