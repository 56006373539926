import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import Config from '../../data.json';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import qs from 'query-string';
import AppActionsOverride from './custom';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const user = UserOutlet.getValue();
      const isAdmin = user.grp.split(':').indexOf('admin') !== -1;
      const result = await User.autoLogin({admin: isAdmin});
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
});

function delay(ms) {
  if (AppActionsOverride.delay) {
    return AppActionsOverride.delay(...arguments);
  }

  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading, params) {
  if (AppActionsOverride.setLoading) {
    return AppActionsOverride.setLoading(...arguments);
  }

  const {message = ''} = params || {};
  setTimeout(() => {
    LoadingOutlet.update({loading: loading, message: message});
  }, 0);
}

function gtag(eventType, event, payload) {
  if (AppActionsOverride.gtag) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

function fbq(eventType, event, payload) {
  if (AppActionsOverride.fbq) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

async function navigate(nextRoute, options = {}) {
  if (AppActionsOverride.navigate) {
    return AppActionsOverride.navigate(...arguments);
  }

  const {message = '', loading = false, ...rest} = options;
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({
        message: options.message,
        loading: options.loading,
      });
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    await nav(nextRoute, rest);
  } else {
    console.log('path not changed, ignore...');
  }
}

function adminCanAccess(user, options = {}) {
  if (AppActionsOverride.adminCanAccess) {
    return AppActionsOverride.adminCanAccess(...arguments);
  }

  return true;
}

/**
 * **************************************************
 * (client) JStorage powered product fetching APIs
 * **************************************************
 */

async function clientJStorageFetch(collection, {cat, sort, search, q, page}) {
  if (AppActionsOverride.clientJStorageFetch) {
    return AppActionsOverride.clientJStorageFetch(...arguments);
  }

  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const pagingValue = page;
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;

    extraQueries['$or'] = [
      {stock_type: {$exists: false}},
      {stock_type: 'always'},
      {stock_type: 'total'},
      {
        stock_type: 'period',
        stock_start_date: {
          $lte: new Date().toISOString().split('T')[0],
        },
        stock_end_date: {
          $gte: new Date().toISOString().split('T')[0],
        },
      },
    ];

    if (search) {
      extraQueries['$or'] = extraQueries['$or'].map((query) => {
        return {
          ...query,
          $or: [{name: {$regex: search}}, {searchText: {$regex: search}}],
        };
      });
    }
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;
    if (!cat) {
      catQuery.labels = 'blog';
    } else {
      catQuery.labels = {$regex: cat};
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...extraQueries,
    },
    sortValue,
    pagingValue,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

function getDefaultCheckoutFormSpec() {
  if (AppActionsOverride.getDefaultCheckoutFormSpec) {
    return AppActionsOverride.getDefaultCheckoutFormSpec(...arguments);
  }

  return {
    paymentSubTypes: [
      Cart.PAYMENT_SUBTYPE.default,
      Cart.PAYMENT_SUBTYPE.credit,
      Cart.PAYMENT_SUBTYPE.cod,
      Cart.PAYMENT_SUBTYPE.offline,
      Cart.PAYMENT_SUBTYPE.atm,
    ],
    logisticsTypes: [Cart.LOGISTICS_TYPE.cvs, Cart.LOGISTICS_TYPE.home],
    logisticsSubTypes: {
      [Cart.LOGISTICS_TYPE.cvs]: [
        Cart.LOGISTICS_SUBTYPE.famic2c,
        Cart.LOGISTICS_SUBTYPE.hilifec2c,
        Cart.LOGISTICS_SUBTYPE.unimartc2c,
      ],
      [Cart.LOGISTICS_TYPE.home]: [
        Cart.LOGISTICS_SUBTYPE.TCAT,
        Cart.LOGISTICS_SUBTYPE.ECAN,
      ],
    },
    invoiceCategories: [Cart.INVOICE_CATEGORY.b2c, Cart.INVOICE_CATEGORY.b2b],
    invoiceCarrierTypes: [
      Cart.INVOICE_CARRIER_TYPE.none,
      Cart.INVOICE_CARRIER_TYPE.ecpay,
      Cart.INVOICE_CARRIER_TYPE.cdc,
      Cart.INVOICE_CARRIER_TYPE.mobile,
    ],
  };
}

function onCartLoaded(cart) {
  if (AppActionsOverride.onCartLoaded) {
    return AppActionsOverride.onCartLoaded(...arguments);
  }

  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  return {
    updateConfig,
    checkoutFormSpec,
  };
}

// 建立物流訂單 ( 通常會自行建立，此 api 用於意外發生，手動重新建立物流訂單 ）
async function createLogisticsOrder(id) {
  if (AppActionsOverride.createLogisticsOrder) {
    return AppActionsOverride.createLogisticsOrder(...arguments);
  }

  return await req(
    `${Config.apiHost}/order/logistics/create?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'post',
      data: {
        id: id,
      },
    },
  );
}

async function rebuild() {
  if (AppActionsOverride.rebuild) {
    return AppActionsOverride.rebuild(...arguments);
  }

  await req('https://api.netlify.com/build_hooks/615418bee44904a94bd7b4ab', {
    method: 'POST',
    data: {},
  });
}

async function fetchCustomResources(
  resource,
  {sort, keyword, filter, paging, extraQueries},
) {
  if (AppActionsOverride.fetchCustomResources) {
    return AppActionsOverride.fetchCustomResources(...arguments);
  }

  return null;
}

async function onLoginResult(err, result) {
  if (AppActionsOverride.onLoginResult) {
    return AppActionsOverride.onLoginResult(...arguments);
  }

  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
            provider: privateProfile.provider,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart();
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function confirmOfflineOrder(id) {
  if (AppActionsOverride.confirmOfflineOrder) {
    return AppActionsOverride.confirmOfflineOrder(...arguments);
  }

  await req(
    `${Config.apiHost}/order/offline?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        id: id,
      },
    },
  );
}

async function editUserPrivateProfile(id, points) {
  if (AppActionsOverride.editUserPrivateProfile) {
    return AppActionsOverride.editUserPrivateProfile(...arguments);
  }

  return await req(
    `${Config.apiHost}/user/points?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id: id,
        points,
      },
    },
  );
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  if (AppActionsOverride.onAdminFormSubmit) {
    return AppActionsOverride.onAdminFormSubmit(...arguments);
  }

  if (collection === 'product' && formData.stock_type === 'period') {
    if (
      typeof formData.stock_start_date === 'string' &&
      typeof formData.stock_duration === 'number'
    ) {
      const theDate = new Date(formData.stock_start_date);
      const saleDuration = formData.stock_duration;
      theDate.setDate(theDate.getDate() + saleDuration - 1);
      formData.stock_end_date = theDate.toISOString().split('T')[0];
    }
  }

  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  if (AppActionsOverride.onAfterAdminFormSubmit) {
    return AppActionsOverride.onAfterAdminFormSubmit(...arguments);
  }

  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/config') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  if (collection === 'return_request') {
    if (
      instance.status !== formData.status &&
      formData.status === 'return_applied'
    ) {
      try {
        await Cart.returnConfirm(instance.id, 'return_applied');
      } catch (ex) {
        console.warn(ex);
      }
    }
  }

  return null;
}

function getReurl({title, description, image, redirectUrl}) {
  if (AppActionsOverride.getReurl) {
    return AppActionsOverride.getReurl(...arguments);
  }

  return `${Config.apiHost}/misc/reurl?title=${title}&image=${image}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

async function selectCVS({logisticsSubType}) {
  if (AppActionsOverride.selectCVS) {
    return AppActionsOverride.selectCVS(...arguments);
  }

  window.location.href = `${Config.apiHost}/misc/cvs-map?${qs.stringify({
    logistics_subtype: logisticsSubType,
    redirect_url: `${window.location.origin}${window.location.pathname}`,
  })}`;
}

// @sdk-next
function createCustomOrder(payload) {
  if (AppActionsOverride.createCustomOrder) {
    return AppActionsOverride.createCustomOrder(...arguments);
  }

  const {
    user_id,
    name,
    total,
    description,
    buyer_name,
    buyer_email,
    buyer_phone,
  } = payload;
  return req(
    `${Config.apiHost}/checkout/custom?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id,
        name,
        total,
        description,
        buyer_name,
        buyer_email,
        buyer_phone,
        payment_subtype: 'offline',
      },
    },
  );
}

export {
  delay,
  setLoading,
  navigate,
  adminCanAccess,
  clientJStorageFetch,
  fetchCustomResources,
  onLoginResult,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  onCartLoaded,
  createLogisticsOrder,
  rebuild,
  getReurl,
  confirmOfflineOrder,
  editUserPrivateProfile,
  createCustomOrder,
  selectCVS,
  gtag,
  fbq,
};
