import React from 'react';
import styled from 'styled-components';
import NavLink from '../../NavLink';

export default function MobileNavDrawer(props) {
  const {visible, setVisible} = props;

  return (
    <>
      <Backdrop visible={visible} onClick={() => setVisible(false)} />
      <MobileContent visible={visible}>
        <div className="logo-box">
          <NavLink to="/" loading={800}>
            <img
              src="/images/site-logo-drawer.png"
              alt="site logo"
              style={{width: 144, height: 144}}
            />
          </NavLink>
        </div>

        {props.children}
      </MobileContent>
    </>
  );
}

const Backdrop = styled.div`
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: 300ms;
`;

const MobileContent = styled.div`
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  width: 240px;
  height: 100vh;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: 300ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) =>
    props.visible ? 'translateX(0px)' : 'translateX(-300px)'};

  & > .logo-box {
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
    margin-bottom: 20px;
  }

  & > .nav-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
