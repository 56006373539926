import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import Config from '../../../data.json';
import {Helmet} from 'react-helmet';
import SiteNavBar from '../SiteNavBar';
import ProfileLayout from '../ProfileLayout';
import SiteFooter from '../SiteFooter';
import CheckoutLayout from '../CheckoutLayout';
import styled from 'styled-components';
import 'antd/dist/antd.less';
import './Layout.css';
import './custom.css';
import styledCss from './styledCss';
import customStyledCss from './customStyledCss';
import useFacebookPixel from '../../Hooks/useFacebookPixel';
import useGoogleAnalytics from '../../Hooks/useGoogleAnalytics';
import PleaseWait from '../PleaseWait';
import useIsInApp from 'rev.sdk.js/Hooks/useIsInApp';
import constants from '../../constants';

function Layout(props) {
  const {children, location} = props;
  const {SITE_INFO} = constants;
  const isInApp = useIsInApp();
  const [dimension] = useOutlet('dimension');
  const [initialized, setInitialized] = React.useState(false);
  useFacebookPixel({pixelId: Config.pixelId});
  useGoogleAnalytics({gaId: Config.gaId, gtmId: Config.gtmId});

  const [lang, setLang] = React.useState('tw');

  React.useEffect(() => {
    const initialize = async (isAdmin) => {
      setInitialized(true);
    };

    if (location.pathname.indexOf('en') >= 0) {
      setLang('en');
    }
  }, [location.pathname, initialized]);

  if (location.pathname.indexOf('profile') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <Wrapper rwd={dimension.rwd}>
        <SiteNavBar {...props} />
        <ProfileLayout style={{flex: 1}} location={location}>
          {children}
        </ProfileLayout>
        <SiteFooter {...props} />
      </Wrapper>
    );
  } else if (location.pathname.indexOf('checkout') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="initial-scale=1.0, maximum-scale=1.0"
          />
          <meta property="og:title" content="RealiT" />
          <meta
            name="description"
            content="RealiT：區塊鏈資產實體化標籤系統。Bring digital to your reality"
          />
          <meta
            propert="og:description"
            content="RealiT：區塊鏈資產實體化標籤系統。Bring digital to your reality"
          />

          <meta name="image" content="/realiT-icon.JPG" />
          <meta property="og:image" content="/realiT-icon.JPG" />
        </Helmet>
        <Wrapper rwd={dimension.rwd}>
          {!isInApp && <SiteNavBar {...props} lang={lang} />}
          <CheckoutLayout style={{flex: 1}} location={location}>
            {children}
          </CheckoutLayout>
          {!isInApp && <SiteFooter {...props} lang={lang} />}
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{SITE_INFO.title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
        />
        <meta property="og:title" content="RealiT" />
        <meta
          name="description"
          content="RealiT：區塊鏈資產實體化標籤系統。Bring digital to your reality"
        />
        <meta
          propert="og:description"
          content="RealiT：區塊鏈資產實體化標籤系統。Bring digital to your reality"
        />

        <meta
          name="image"
          content="https://www.realitag.app/Realit-logo-512-512px.png"
        />
        <meta
          property="og:image"
          content="https://www.realitag.app/Realit-logo-512-512px.png"
        />

        <meta
          name="google-site-verification"
          content="3Tdn35RZbdteJ_2vauVUouae9q8LY6bEvfGf_J8weXo"
        />
      </Helmet>
      <Wrapper rwd={dimension.rwd}>
        <SiteNavBar {...props} lang={lang} />
        <div style={{flex: 1}}>{children}</div>
        <SiteFooter {...props} lang={lang} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  ${styledCss}
  ${customStyledCss}
`;

export default Layout;
