import * as CustomConstants from './custom';

const THEME_COLOR = '#C3AAD1';

const SITE_INFO = {
  title: 'RealiT',
  subtitle: '',
  icon: '/realiT-icon.JPG',
  address: '103 台北市大同區民權西路136號10樓之5',
};

const SITE_CONFIG = {
  config: {display: '設定', value: 'config'},
  landing: {display: '首頁設定', value: 'landing'},
  product_category: {display: '商品分類', value: 'product_category'},
  article_category: {display: '文章分類', value: 'article_category'},
  navbar_config: {display: '頁眉設定', value: 'navbar_config'},
  footer_config: {display: '頁尾設定', value: 'footer_config'},
};

const SPINNER_CONFIG = {
  image: '/Realit-logo-512-512px.png',
};

const constants = {
  THEME_COLOR,
  SITE_INFO,
  SITE_CONFIG,
  SPINNER_CONFIG,
  ...CustomConstants,
};

export default constants;
