import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

export function getExtraLoginModalProps() {
  return {
    canFacebookLogin: true,
    canLineLogin: true,
    canGoogleLogin: true,
    canForgetPassword: true,
    canRegister: true,
    onForgotPasswordClick: null,
    onRegisterClick: null,
    renderExtra: () => {
      return (
        <Wrapper>
          <p className="text">
            擁有帳號即代表您同意
            <Button
              type="link"
              size="small"
              target="_blank"
              href="/privacy-policy">
              隱私權政策
            </Button>
          </p>
        </Wrapper>
      );
    },
  };
}

const Wrapper = styled.div`
  margin-bottom: 25px;

  & > .text {
    text-align: center;
    font-size: 13px;
  }
`;
