import React from 'react';
import styled from 'styled-components';
import NavLink from '../NavLink';
import * as NavUtil from '../../Utils/NavUtil';

export default function SiteFooter(props) {
  const footerConfig = NavUtil.getFooterConfig(props);
  const navItems = NavUtil.getFooterNavItems(props);

  return (
    <Wrapper id="rev-SiteFooter">
      <div className="content">
        <img
          className="site-logo"
          src="/images/site-logo.png"
          alt="site footer"
        />

        <div className="nav-items">
          {navItems.map((navItem, idx) => {
            return (
              <>
                {idx !== 0 && (
                  <div className="bar" key={`${idx}_bar`}>
                    |
                  </div>
                )}

                <NavLink key={idx} to={navItem.path}>
                  {navItem.name}
                </NavLink>
              </>
            );
          })}
        </div>

        <div className="social-items">
          {footerConfig.social_fb && (
            <a href={footerConfig.social_fb} target="_blank" rel="noreferrer">
              <img src="/images/fb.png" alt="fb" />
            </a>
          )}

          {footerConfig.social_ig && (
            <a href={footerConfig.social_ig} target="_blank" rel="noreferrer">
              <img src="/images/ig.png" alt="ig" />
            </a>
          )}

          {footerConfig.social_line && (
            <a href={footerConfig.social_line} target="_blank" rel="noreferrer">
              <img src="/images/line.png" alt="fb" />
            </a>
          )}
        </div>

        <div className="contact-items">
          <div className="title">Contact Us</div>
          <div className="email">
            {footerConfig.contact_email || 'contact@hishop.com'}
          </div>
          {footerConfig.contact_phone && (
            <div className="phone">{footerConfig.contact_phone}</div>
          )}
          {footerConfig.contact_addr && (
            <div className="addr">{footerConfig.contact_addr}</div>
          )}
          {footerConfig.contact_time && (
            <div className="time">{footerConfig.contact_time}</div>
          )}
        </div>

        <div className="copyright">
          {footerConfig.copyright || '2021 HiShop Powered By Revteltech'}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #eee;

  & > .content {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 10px 0;
    }

    & > img.site-logo {
      width: 72px;
      height: 72px;
      object-fit: contain;
    }

    & > .nav-items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      & > *:not(:last-child) {
        margin: 0 5px;
      }
    }

    & > .social-items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      & > * {
        margin: 0 5px;

        & img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          border-radius: 16px;
          overflow: hidden;
          background-color: #888;
        }
      }
    }

    & > .contact-items {
      align-self: stretch;

      & > * {
        margin: 3px 0;
        font-size: 0.9rem;
        text-align: center;
        color: #666;

        &.title {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 5px;
          color: black;
        }
      }

      &::after {
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        margin-top: 15px;
        background-color: #ccc;
      }
    }

    & > .copyright {
      color: #888;
    }
  }
`;
